function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ROLE_LOGIN = '/login';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_ROLE_LOGIN = {
  root: ROOTS_ROLE_LOGIN,
  admin: path(ROOTS_ROLE_LOGIN, '/admin'),
  company: path(ROOTS_ROLE_LOGIN, `/company`),
  clinic: path(ROOTS_ROLE_LOGIN, '/clinic'),
  officer: path(ROOTS_ROLE_LOGIN, '/officer'),
  member: path(ROOTS_ROLE_LOGIN, '/member'),
  register: path(ROOTS_ROLE_LOGIN, '/register'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dorm: {
    app: path(ROOTS_DASHBOARD, '/'),
    dorm: path(ROOTS_DASHBOARD, '/dorm'),
    editdorm: (id) => path(ROOTS_DASHBOARD, `/editdorm/${id}`),
    adddorm: path(ROOTS_DASHBOARD, '/adddorm'),
  },
  element: {
    app: path(ROOTS_DASHBOARD, '/'),
    element: path(ROOTS_DASHBOARD, '/element'),
    editelement: (id) => path(ROOTS_DASHBOARD, `/editelement/${id}`),
    companyProfile: path(ROOTS_DASHBOARD, '/companyProfile'),
    addelement: path(ROOTS_DASHBOARD, '/addelement'),
  },
  magictype: {
    app: path(ROOTS_DASHBOARD, '/'),
    magictype: path(ROOTS_DASHBOARD, '/magictype'),
    editmagictype: (id) => path(ROOTS_DASHBOARD, `/editmagictype/${id}`),
    addmagictype: path(ROOTS_DASHBOARD, '/addmagictype'),
  },
  tag: {
    app: path(ROOTS_DASHBOARD, '/'),
    tag: path(ROOTS_DASHBOARD, '/tag'),
    edittag: (id) => path(ROOTS_DASHBOARD, `/edittag/${id}`),
    addtag: path(ROOTS_DASHBOARD, '/addtag'),
  },
  rarity: {
    app: path(ROOTS_DASHBOARD, '/'),
    rarity: path(ROOTS_DASHBOARD, '/rarity'),
    editrarity: (id) => path(ROOTS_DASHBOARD, `/editrarity/${id}`),
    addrarity: path(ROOTS_DASHBOARD, '/addrarity'),
  },
  // review: {
  //   app: path(ROOTS_DASHBOARD, '/'),
  //   review: path(ROOTS_DASHBOARD, '/review'),
  // },
  spell: {
    app: path(ROOTS_DASHBOARD, '/'),
    spell: path(ROOTS_DASHBOARD, '/user'),
    editspell: (id) => path(ROOTS_DASHBOARD, `/edituser/${id}`),
    addspell: path(ROOTS_DASHBOARD, '/adduser'),
    userdetail: path(ROOTS_DASHBOARD, '/useruser'),
    viewuser: path(ROOTS_DASHBOARD, '/viewuser/:id'),

    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  order: {
    app: path(ROOTS_DASHBOARD, '/'),
    order: path(ROOTS_DASHBOARD, '/order'),
    addorder: path(ROOTS_DASHBOARD, '/addorder'),
    editorder: (id) => path(ROOTS_DASHBOARD, `/editorder/${id}`),
    vieworder: path(ROOTS_DASHBOARD, '/vieworder/:id'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },

  eventbooks: {
    app: path(ROOTS_DASHBOARD, '/'),
    eventbook: path(ROOTS_DASHBOARD, '/eventbook'),
    bookeventview: path(ROOTS_DASHBOARD, '/bookeventview/:id'),

    // addcoupon: path(ROOTS_DASHBOARD, '/addcoupon'),
    // editcoupon: (id) => path(ROOTS_DASHBOARD, `/editcoupon/${id}`),
    // viewcoupon: path(ROOTS_DASHBOARD, '/viewcoupon/:id'),
    // // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    // spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  coupon: {
    app: path(ROOTS_DASHBOARD, '/'),
    coupon: path(ROOTS_DASHBOARD, '/coupon'),
    addcoupon: path(ROOTS_DASHBOARD, '/addcoupon'),
    editcoupon: (id) => path(ROOTS_DASHBOARD, `/editcoupon/${id}`),
    viewcoupon: path(ROOTS_DASHBOARD, '/viewcoupon/:id'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  event: {
    app: path(ROOTS_DASHBOARD, '/'),
    event: path(ROOTS_DASHBOARD, '/event'),
    editevent: (id) => path(ROOTS_DASHBOARD, `/editevent/${id}`),
    addevent: path(ROOTS_DASHBOARD, '/addevent'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewevent: path(ROOTS_DASHBOARD, '/viewevent/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },

  menu: {
    app: path(ROOTS_DASHBOARD, '/'),
    menu: path(ROOTS_DASHBOARD, '/menu'),
    editmenu: (id) => path(ROOTS_DASHBOARD, `/editmenu/${id}`),
    addmenu: path(ROOTS_DASHBOARD, '/addmenu'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewmenu: path(ROOTS_DASHBOARD, '/viewmenu/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },

  item: {
    app: path(ROOTS_DASHBOARD, '/'),
    item: path(ROOTS_DASHBOARD, '/item'),
    edititem: (id) => path(ROOTS_DASHBOARD, `/edititem/${id}`),
    additem: path(ROOTS_DASHBOARD, '/additem'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewitem: path(ROOTS_DASHBOARD, '/vieitem/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  ingradient: {
    app: path(ROOTS_DASHBOARD, '/'),
    ingredient: path(ROOTS_DASHBOARD, '/ingredient'),
    editingredient: (id) => path(ROOTS_DASHBOARD, `/editingredient/${id}`),
    addingredient: path(ROOTS_DASHBOARD, '/addingredient'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewitem: path(ROOTS_DASHBOARD, '/vieitem/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },

  review: {
    app: path(ROOTS_DASHBOARD, '/'),
    review: path(ROOTS_DASHBOARD, '/review'),
    editingredient: (id) => path(ROOTS_DASHBOARD, `/editingredient/${id}`),
    addingredient: path(ROOTS_DASHBOARD, '/addingredient'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    ReviewView: path(ROOTS_DASHBOARD, '/ReviewView/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },

  categoryingradient: {
    app: path(ROOTS_DASHBOARD, '/'),
    categoryingredient: path(ROOTS_DASHBOARD, '/categoryingredient'),
    categoryeditingredient: (id) => path(ROOTS_DASHBOARD, `/categoryeditingredient/${id}`),
    categoryaddingredient: path(ROOTS_DASHBOARD, '/categoryaddingredient'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewitem: path(ROOTS_DASHBOARD, '/vieitem/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  percantage: {
    app: path(ROOTS_DASHBOARD, '/'),
    amount: path(ROOTS_DASHBOARD, '/amount'),
    editamount: (id) => path(ROOTS_DASHBOARD, `/percantage/${id}`),
    addpercantage: path(ROOTS_DASHBOARD, '/percantage'),
    // userdetail: path(ROOTS_DASHBOARD, '/userdetail'),
    viewamount: path(ROOTS_DASHBOARD, '/viewamount/:id'),

    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  reward: {
    app: path(ROOTS_DASHBOARD, '/'),
    reward: path(ROOTS_DASHBOARD, '/reward'),
    viewreward: path(ROOTS_DASHBOARD, '/viewreward/:id'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  host: {
    app: path(ROOTS_DASHBOARD, '/'),
    host: path(ROOTS_DASHBOARD, '/host'),
    edithost: (id) => path(ROOTS_DASHBOARD, `/edithost/${id}`),
    addhost: path(ROOTS_DASHBOARD, '/addhost'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  facility: {
    app: path(ROOTS_DASHBOARD, '/'),
    facility: path(ROOTS_DASHBOARD, '/facility'),
    editfacility: (id) => path(ROOTS_DASHBOARD, `/editfacility/${id}`),
    addfacility: path(ROOTS_DASHBOARD, '/addfacility'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  amenity: {
    app: path(ROOTS_DASHBOARD, '/'),
    amenity: path(ROOTS_DASHBOARD, '/amenity'),
    editamenity: (id) => path(ROOTS_DASHBOARD, `/editamenity/${id}`),
    addamenity: path(ROOTS_DASHBOARD, '/addamenity'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  listing: {
    app: path(ROOTS_DASHBOARD, '/'),
    listing: path(ROOTS_DASHBOARD, '/listing'),
    editlisting: (id) => path(ROOTS_DASHBOARD, `/editlisting/${id}`),
    duplicate: (id) => path(ROOTS_DASHBOARD, `/duplicate/${id}`),

    addlisting: path(ROOTS_DASHBOARD, '/addlisting'),
    viewlisitng: path(ROOTS_DASHBOARD, '/viewlisitng'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  favorite: {
    app: path(ROOTS_DASHBOARD, '/'),
    favorite: path(ROOTS_DASHBOARD, '/allfavorite'),
    editfavorite: (id) => path(ROOTS_DASHBOARD, `/editfavorite/${id}`),
    addfavorite: path(ROOTS_DASHBOARD, '/addfavorite'),
    viewfavorite: path(ROOTS_DASHBOARD, '/viewfavorite'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  booking: {
    app: path(ROOTS_DASHBOARD, '/'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    // editlisting: (id) => path(ROOTS_DASHBOARD, `/editlisting/${id}`),
    // addlisting: path(ROOTS_DASHBOARD, '/addlisting'),
    viewbooking: path(ROOTS_DASHBOARD, '/viewbooking'),
    confirmbooking: path(ROOTS_DASHBOARD, '/confirmbooking'),

    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  trip: {
    app: path(ROOTS_DASHBOARD, '/'),
    trip: path(ROOTS_DASHBOARD, '/trip'),
    // editlisting: (id) => path(ROOTS_DASHBOARD, `/editlisting/${id}`),
    // addlisting: path(ROOTS_DASHBOARD, '/addlisting'),
    viewtrip: path(ROOTS_DASHBOARD, '/viewtrip'),
    // confirmbooking: path(ROOTS_DASHBOARD, '/confirmbooking'),

    // spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  message: {
    app: path(ROOTS_DASHBOARD, '/'),
    message: path(ROOTS_DASHBOARD, '/message'),
    blog: path(ROOTS_DASHBOARD, '/blog'),
    addblog: path(ROOTS_DASHBOARD, '/addblog'),
    editblog: (id) => path(ROOTS_DASHBOARD, `/editblog/${id}`),
    viewblog: (id) => path(ROOTS_DASHBOARD, `/viewblog/${id}`),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  payouts: {
    app: path(ROOTS_DASHBOARD, '/'),
    payouts: path(ROOTS_DASHBOARD, '/payouts'),
    payoutssearch: (id) => path(ROOTS_DASHBOARD, `/payoutssearch/${id}`),
    payoutsdetails: path(ROOTS_DASHBOARD, '/payoutsdetails'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  invoices: {
    app: path(ROOTS_DASHBOARD, '/'),
    invoices: path(ROOTS_DASHBOARD, '/invoices'),
    invoicesdetails: path(ROOTS_DASHBOARD, '/invoicesdetails'),
    spelleffect: (id) => path(ROOTS_DASHBOARD, `/spelleffect/${id}`),
  },
  slot: {
    app: path(ROOTS_DASHBOARD, '/'),
    slot: (id) => path(ROOTS_DASHBOARD, `/slot/${id}`),
    editslot: (id) => path(ROOTS_DASHBOARD, `/editslot/${id}`),
    addslot: path(ROOTS_DASHBOARD, '/addslot'),
    addslotById: (id) => path(ROOTS_DASHBOARD, `/addslot/${id}`),
  },
  character: {
    app: path(ROOTS_DASHBOARD, '/'),
    character: path(ROOTS_DASHBOARD, '/character'),
    editcharacter: (id) => path(ROOTS_DASHBOARD, `/editcharacter/${id}`),
    addcharacter: path(ROOTS_DASHBOARD, '/addcharacter'),
  },
  appointment: {
    app: path(ROOTS_DASHBOARD, '/'),
    appointment: path(ROOTS_DASHBOARD, '/appointment'),
    editappointment: (id) => path(ROOTS_DASHBOARD, `/editappointment/${id}`),
    appointmentdetail: (id) => path(ROOTS_DASHBOARD, `/appointmentdetail/${id}`),
    addappointment: path(ROOTS_DASHBOARD, '/addappointment'),
  },
  card: {
    app: path(ROOTS_DASHBOARD, '/'),
    card: path(ROOTS_DASHBOARD, '/card'),
    editcard: (id) => path(ROOTS_DASHBOARD, `/editcard/${id}`),
    carddetail: (id) => path(ROOTS_DASHBOARD, `/carddetail/${id}`),
    addcard: path(ROOTS_DASHBOARD, '/addcard'),
  },

  general: {
    app: path(ROOTS_DASHBOARD, '/'),
    subscription: path(ROOTS_DASHBOARD, '/subscription'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   new: path(ROOTS_DASHBOARD, '/blog/new'),
  //   view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
  //   demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
