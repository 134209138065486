/* eslint-disable no-unused-vars */
import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import GiteIcon from '@mui/icons-material/Gite';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { Lock } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MessageIcon from '@mui/icons-material/Message';
import BookIcon from '@mui/icons-material/Book';
import { PATH_DASHBOARD } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      // { title: 'Profile', path: '/dashboard/editadmin', icon: <PeopleAltIcon /> },
      // { title: 'User', path: PATH_DASHBOARD.listing.listing, icon: <FormatListBulletedIcon /> },
      { title: 'Users', path: PATH_DASHBOARD.spell.spell, icon: <PersonIcon /> },
      { title: 'Category', path: PATH_DASHBOARD.menu.menu, icon: <Icon icon="material-symbols:menu-book" /> },
      { title: 'Items', path: PATH_DASHBOARD.item.item, icon: <Icon icon="ic:round-production-quantity-limits" /> },
      { title: 'Coupons', path: PATH_DASHBOARD.coupon.coupon, icon: <Icon icon="ri:coupon-3-fill" /> },
      { title: 'Events', path: PATH_DASHBOARD.event.event, icon: <Icon icon="carbon:event" /> },
      { title: 'Orders', path: PATH_DASHBOARD.order.order, icon: <Icon icon="lsicon:order-filled" /> },
      { title: 'Book Events', path: PATH_DASHBOARD.eventbooks.eventbook, icon: <Icon icon="lsicon:order-filled" /> },
      {
        title: 'Category Ingredient',
        path: PATH_DASHBOARD.categoryingradient.categoryingredient,
        icon: <Icon icon="lsicon:order-filled" />,
      },
      { title: 'Review', path: PATH_DASHBOARD.review.review, icon: <Icon icon="lsicon:order-filled" /> },

      // { title: 'Reward', path: PATH_DASHBOARD.reward.reward, icon: <RamenDiningIcon /> },
      // { title: 'Message', path: PATH_DASHBOARD.message.message, icon: <MessageIcon /> },
      // { title: 'Request', path: PATH_DASHBOARD.payouts.payouts, icon: <RamenDiningIcon /> },
      // { title: 'Invoices', path: PATH_DASHBOARD.invoices.invoices, icon: <RamenDiningIcon /> },
      // { title: 'Favorite', path: PATH_DASHBOARD.favorite.favorite, icon: <Icon icon="mdi:favorite" /> },
      // { title: 'Logout', path: '/', icon: <Lock /> },

      // { title: 'Host', path: PATH_DASHBOARD.host.host, icon: <GiteIcon /> },
      // { title: 'Facility', path: PATH_DASHBOARD.facility.facility, icon: <PeopleAltIcon /> },
      // { title: 'Amenity', path: PATH_DASHBOARD.amenity.amenity, icon: <RamenDiningIcon /> },

      // { title: 'Blog', path: PATH_DASHBOARD.message.blog, icon: <BookIcon /> },
    ],
  },
];

export default navConfig;
